import * as React from "react";
import Header from "../components/Header";
import Footer from "../components/Footer";
import Spacer from "../components/Spacer";
import Mujeres from "../images/mujeresdelapalabra.jpeg";
import StarIcon from "../components/StarIcon";

// markup
const ComunidadPage = () => {
  // window.addEventListener("scroll", throttle(parallax, 14));

  function throttle(fn, wait) {
    var time = Date.now();
    return function () {
      if (time + wait - Date.now() < 0) {
        fn();
        time = Date.now();
      }
    };
  }

  function parallax() {
    var parallaxElem = document.querySelector(".img-parallax-block img");
    var offset = parallaxElem.getBoundingClientRect();
    var scrolled = window.pageYOffset;

    console.log(["offset.top", offset.top]);
    // You can adjust the 0.4 to change the speed
    var coords = -(scrolled * 0.3) + "px";
    parallaxElem.style.transform = "translate(10px," + coords + ")";
  }
  return (
    <>
      <Header></Header>
      <div className="Index-page-content">
        <div
          className="sqs-layout sqs-grid-12 columns-12"
          data-type="page"
          data-updated-on="1457625959534"
          id="page-56b231af4d088e92779c5d20"
        >
          <div className="row sqs-row" id="yui_3_17_2_1_1633227834863_306">
            <div
              className="col sqs-col-12 span-12"
              id="yui_3_17_2_1_1633227834863_305"
            >
              <div
                className="sqs-block html-block sqs-block-html"
                data-block-type="2"
                id="block-56b231af4d088e92779c5d21"
              >
                <div className="sqs-block-content">
                  <h1 style={{ textAlign: "center" }}>Comunidad</h1>
                </div>
              </div>

              <div
                className="sqs-block spacer-block sqs-block-spacer"
                data-aspect-ratio="2.2"
                data-block-type="21"
                id="block-yui_3_17_2_19_1454526015148_21520"
              >
                <div
                  className="sqs-block-content sqs-intrinsic"
                  id="yui_3_17_2_1_1633227834863_134"
                >
                  &nbsp;
                </div>
              </div>
              <div className="row sqs-row">
                <div className="col sqs-col-4 span-4">
                  <div
                    className="sqs-block html-block sqs-block-html"
                    data-block-type="2"
                    id="block-yui_3_17_2_22_1455045805581_72424"
                  >
                    <div className="sqs-block-content">
                      <h1>―</h1>
                    </div>
                  </div>
                </div>
                <div className="col sqs-col-8 span-8">
                  <div
                    className="sqs-block html-block sqs-block-html"
                    data-block-type="2"
                    id="block-yui_3_17_2_22_1455045805581_73767"
                  >
                    <div className="sqs-block-content">
                      <p>
                        En Gracia Sobre Gracia deseamos vivir en comunidad
                        disfrutando de lo que Dios ha creado en Cristo, Su
                        familia llamada la iglesia. Para ese fin buscamos el
                        hacer vida juntos mientras aprendemos la Biblia y como
                        aplicarla a nuestra vida. Esperamos puedas unirte a ser
                        parte de lo que Dios ha diseñado en Su familia para
                        juntos caminar en el aquí y el ahora con la mirada en el
                        allá y para siempre.
                      </p>
                    </div>
                  </div>
                </div>
              </div>

              <div
                className="sqs-block spacer-block sqs-block-spacer"
                data-aspect-ratio="2.2"
                data-block-type="21"
                id="block-yui_3_17_2_14_1454518413574_7661"
              >
                <div
                  className="sqs-block-content sqs-intrinsic"
                  id="yui_3_17_2_1_1633227834863_131"
                >
                  &nbsp;
                </div>
              </div>
              <div
                className="sqs-block html-block sqs-block-html"
                data-block-type="2"
                id="block-yui_3_17_2_14_1454518413574_26964"
              >
                <div className="sqs-block-content">
                  <h1 style={{ textAlign: "center" }}>Grupos semanales</h1>
                </div>
              </div>
              <p>
                Dios nos ha unido y traído de diferentes ciudades del Sur de la
                Florida, y para cultivar una vida en comunidad creemos que es
                vital para tu crecimiento espiritual que te unas a otros que son
                parte de GSG pero viven en tu comunidad. Por eso en este momento
                tenemos grupos de comunidad que se reúnen todas las semanas en
                ciudades diferentes pero con una misma visión: Ser transformados
                por el evangelio de Cristo para que impactemos nuestra familia,
                iglesia y comunidad.
              </p>
              <div className="row sqs-row">
                <div className="col sqs-col-3 span-3">
                  <StarIcon />
                  <div
                    className="sqs-block html-block sqs-block-html"
                    data-block-type="2"
                    id="block-yui_3_17_2_19_1454526015148_22262"
                  >
                    <div
                      className="sqs-block-content"
                      style={{ textAlign: "center" }}
                    >
                      <h3>Weston</h3>
                      <p>Miércoles 7:15pm</p>
                    </div>
                  </div>
                </div>
                <div className="col sqs-col-3 span-3">
                  <StarIcon />
                  <div
                    className="sqs-block html-block sqs-block-html"
                    data-block-type="2"
                    id="block-yui_3_17_2_19_1454526015148_23534"
                  >
                    <div
                      className="sqs-block-content"
                      style={{ textAlign: "center" }}
                    >
                      <h3>Sunrise</h3>
                      <p>Wednesdays 7:15pm (English)</p>
                    </div>
                  </div>
                </div>
                <div className="col sqs-col-3 span-3">
                  <StarIcon />
                  <div
                    className="sqs-block html-block sqs-block-html"
                    data-block-type="2"
                    id="block-yui_3_17_2_19_1454526015148_25638"
                  >
                    <div
                      className="sqs-block-content"
                      style={{ textAlign: "center" }}
                    >
                      <h3>Tamarac</h3>
                      <p>Miercoles 7:15pm</p>
                    </div>
                  </div>
                </div>
                <div className="col sqs-col-3 span-3">
                  <StarIcon />
                  <div
                    className="sqs-block html-block sqs-block-html"
                    data-block-type="2"
                    id="block-yui_3_17_2_19_1454526015148_22262"
                  >
                    <div
                      className="sqs-block-content"
                      style={{ textAlign: "center" }}
                    >
                      <h3>Pembroke Pines</h3>
                      <p>Viernes 7:30pm</p>
                    </div>
                  </div>
                </div>
              </div>

              <div
                className="sqs-block spacer-block sqs-block-spacer"
                data-aspect-ratio="2.2"
                data-block-type="21"
                id="block-yui_3_17_2_14_1454518413574_7661"
              >
                <div
                  className="sqs-block-content sqs-intrinsic"
                  id="yui_3_17_2_1_1633227834863_131"
                >
                  &nbsp;
                </div>
              </div>

              <div className="sqs-block-content">
                <hr />
              </div>

              <div
                className="sqs-block spacer-block sqs-block-spacer"
                data-aspect-ratio="2.2"
                data-block-type="21"
                id="block-yui_3_17_2_14_1454518413574_7661"
              >
                <div
                  className="sqs-block-content sqs-intrinsic"
                  id="yui_3_17_2_1_1633227834863_131"
                >
                  &nbsp;
                </div>
              </div>

              <div
                className="sqs-block html-block sqs-block-html"
                data-block-type="2"
                id="block-yui_3_17_2_14_1454518413574_26964"
              >
                <div className="sqs-block-content">
                  <h1 style={{ textAlign: "center" }}>Reuniones</h1>
                </div>
              </div>
              <div className="img-parallax-block">
                <img
                  src={Mujeres}
                  data-speed="-0.25"
                  className="img-parallax"
                  style={{ transform: "translate(0%, -22%)" }}
                />
                <h2>&nbsp;</h2>
              </div>
              <div className="row sqs-row">
                <div className="col sqs-col-4 span-4">
                  <div
                    className="sqs-block html-block sqs-block-html"
                    data-block-type="2"
                    id="block-yui_3_17_2_6_1454519593765_8808"
                  >
                    <div className="sqs-block-content">
                      <h2>Mujeres de la Palabra</h2>
                    </div>
                  </div>
                </div>
                <div className="col sqs-col-8 span-8">
                  <div
                    className="sqs-block html-block sqs-block-html"
                    data-block-type="2"
                    id="block-yui_3_17_2_6_1454519593765_9601"
                  >
                    <div className="sqs-block-content">
                      <p>
                        Primer Sábado del Mes a las
                        10:00 A.M. Deseamos ver mujeres que usen sus dones y
                        talentos en GSG. Por eso mismo, oramos para que Dios
                        haga de las mujeres de GSG, mujeres que estudien y vivan
                        conforme a la Palabra de Dios. ¡Únete para crecer
                        juntas!
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div
                className="sqs-block spacer-block sqs-block-spacer"
                data-aspect-ratio="2.2"
                data-block-type="21"
                id="block-yui_3_17_2_6_1454519593765_22966"
              >
                <div
                  className="sqs-block-content sqs-intrinsic"
                  id="yui_3_17_2_1_1633227834863_140"
                >
                  &nbsp;
                </div>
              </div>
              <div className="row sqs-row">
                <div className="col sqs-col-4 span-4">
                  <div
                    className="sqs-block html-block sqs-block-html"
                    data-block-type="2"
                    id="block-yui_3_17_2_6_1454519593765_23653"
                  >
                    <div className="sqs-block-content">
                      <h2>Hombres De Gracia</h2>
                    </div>
                  </div>
                </div>
                <div className="col sqs-col-8 span-8">
                  <div
                    className="sqs-block html-block sqs-block-html"
                    data-block-type="2"
                    id="block-yui_3_17_2_6_1454519593765_24927"
                  >
                    <div className="sqs-block-content">
                      <p>
                        Primer Sábado del Mes a las 10:00 A.M. Toda familia,
                        iglesia y comunidad necesita de hombres que vivan para
                        Cristo. Por lo tanto, oramos para que Dios levante
                        Hombres de Gracia en GSG para vivan según del diseño de
                        Dios siendo creados para liderar, proveer y proteger.
                        ¡Ven y busquemos del Señor juntos!
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div
                className="sqs-block spacer-block sqs-block-spacer"
                data-aspect-ratio="2.2"
                data-block-type="21"
                id="block-yui_3_17_2_6_1454519593765_27362"
              >
                <div
                  className="sqs-block-content sqs-intrinsic"
                  id="yui_3_17_2_1_1633227834863_143"
                >
                  &nbsp;
                </div>
              </div>
              <div className="row sqs-row">
                <div className="col sqs-col-4 span-4">
                  <div
                    className="sqs-block html-block sqs-block-html"
                    data-block-type="2"
                    id="block-yui_3_17_2_6_1454519593765_28123"
                  >
                    <div className="sqs-block-content">
                      <h2>Growth</h2>
                    </div>
                  </div>
                </div>
                <div className="col sqs-col-8 span-8">
                  <div
                    className="sqs-block html-block sqs-block-html"
                    data-block-type="2"
                    id="block-yui_3_17_2_6_1454519593765_29252"
                  >
                    <div className="sqs-block-content">
                      <p>
                        Sundays at 9:40am Diseñado para los estudiantes de 6th
                        grado a 9th grado y es enseñado en Ingles.
                      </p>
                    </div>
                  </div>
                </div>
              </div>

              <div className="row sqs-row">
                <div className="col sqs-col-4 span-4">
                  <div
                    className="sqs-block html-block sqs-block-html"
                    data-block-type="2"
                    id="block-yui_3_17_2_6_1454519593765_28123"
                  >
                    <div className="sqs-block-content">
                      <h2>Grace kids</h2>
                    </div>
                  </div>
                </div>
                <div className="col sqs-col-8 span-8">
                  <div
                    className="sqs-block html-block sqs-block-html"
                    data-block-type="2"
                    id="block-yui_3_17_2_6_1454519593765_29252"
                  >
                    <div className="sqs-block-content">
                      <p>
                        Domingos en servicio Nosotros creemos que nuestros hijos
                        son una bendición de Dios, no solo para los padres sino
                        también para la iglesia. Por eso mismo, como iglesia
                        queremos unirnos a los padres para discipular y cuidar
                        de nuestros hijos. En Grace Kids estamos comprometidos a
                        ser fieles a Dios con el tiempo y la oportunidad que El
                        nos ha dado al tener a estos niños en GSG para ver el
                        Salmo 78:5–7 una realidad en nuestros hijos. En Grace
                        Kids ellos aprenderán de Dios y Su obra redentora en
                        Cristo mientras son liderados por personas capacitadas
                        que oran y buscan la madurez espiritual de nuestros
                        niños al exponerlos a la mas preciosa y poderosa
                        historia de la Biblia por medio del Espíritu Santo. En
                        GSG deseamos modelar y enseñar a nuestro hijos el como
                        adorar a nuestro Salvador y Señor Jesucristo. Por lo
                        tanto, le damos la bienvenida y participar en el tiempo
                        de adoración con sus padres. Una vez el tiempo de
                        adoración termine pedimos que lleves a tus niños para
                        ser registrados y así puedas regresar al servicio.
                        ¿Preguntas? ¡Aquí estamos para servirte!
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div
                className="sqs-block spacer-block sqs-block-spacer sized vsize-1"
                data-block-type="21"
                id="block-yui_3_17_2_13_1454519593765_11541"
              >
                <div className="sqs-block-content">&nbsp;</div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Footer></Footer>
    </>
  );
};

export default ComunidadPage;
