import React from "React";

const StarIcon = () => {
  return (
    <div className="sqs-block image-block sqs-block-image sqs-text-ready">
      <div className="sqs-block-content" id="yui_3_17_2_1_1636231946566_111">
        <div className="image-block-outer-wrapper layout-caption-below design-layout-inline sqs-narrow-width">
          <figure
            className="sqs-block-image-figure intrinsic"
            style={{ maxWidth: "50px" }}
          >
            <div className="image-block-wrapper">
              <div className="sqs-image-shape-container-element has-aspect-ratio">
                <img
                  className="thumb-image loaded"
                  src="https://images.squarespace-cdn.com/content/v1/56d47d412b8ddea29eb012d8/1460740837580-S0L0R7CM13GK79QYW6YX/image-asset.png?format=100w"
                />
              </div>
            </div>
          </figure>
        </div>
      </div>
    </div>
  );
};

export default StarIcon;
